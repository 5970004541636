import React from 'react'
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  usePermissions,
  FormDataConsumer,
  minValue,
  Toolbar,
  SaveButton,
} from 'react-admin'
// import useBusinessAreaId from '../../utils/useBusinessAreaId'

import {
  DateInput,
  SelectorInput,
  BrandsInput,
} from '../../inputs'

import {
  useMaxLengthValidator,
  useStringValidator,
  useWithBrands,
  useIntervalDateValidator,
} from '../../utils'

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
        transform={(data) => {
            if (data.initialDate === '') data.initialDate = null
            if (data.endDate === '') data.endDate = null
            return data
        }}
        submitOnEnter={false}
        />
    </Toolbar>
)

export default props => {
  const { permissions } = usePermissions()
  const withBrands = useWithBrands()
  const maxLengthValidator = useMaxLengthValidator('description')
  const intervalDateValidator = useIntervalDateValidator()
  const stringValidator = useStringValidator()
  // const businessAreaId = useBusinessAreaId()

  const minValueValidator = [minValue(0)]

  return (
    <Create {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Label parameters">
          {/* {businessAreaId === '5' && (
            <SelectorInput source="businessArea" validate={required()} resettable />
          )}  */}
          <SelectorInput source="campaignLevel" />
          <SelectorInput source="country" />
          <SelectorInput source="channelSource" />
          <SelectorInput source="campaignType" />
          <SelectorInput parse={string => string} source="destination" />
          <SelectorInput source="agency" />
          <SelectorInput source="campaign" />
          <SelectorInput source="objective" />
          <FormDataConsumer>
            {({ formData, ...rest }) => withBrands(formData.campaignLevelId) && <BrandsInput {...rest} />}
          </FormDataConsumer>
          <TextInput source="description" validate={[maxLengthValidator, stringValidator]} />
        </FormTab>
        {['ROLE_ADMIN', 'ROLE_COSTS'].includes(permissions?.labelling) && (
          <FormTab label="Cost allocation">
            <NumberInput source="amount" validate={minValueValidator} parse={v => v.toString()} min={0}/>
            <SelectorInput source="currency" optional />
            <NumberInput source="impressions" validate={minValueValidator} step={1} parse={v => v.toString()} min={0}/>
            <DateInput source="initialDate" />
            <DateInput source="endDate" validate={intervalDateValidator} />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  )
}
