import React from 'react'
import {
  List,
  Datagrid,
  EmailField,
  Filter,
  TextInput,
  Pagination
} from 'react-admin'

import { RoleField, FullnameField } from '../../fields'

import { FilterSelector } from '../../inputs'



const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" />
      <TextInput source="surname" />
      <FilterSelector source="businessAreaId" reference="BusinessAreas"/>
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    title="Examples"
    filters={<Filters />}
    bulkActionButtons={false}
    exporter={false}
    pagination={<PostPagination />}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <FullnameField />
      <EmailField source="email" />
      <RoleField label="Labelling" />
      <RoleField label="Events" />
      {/* <SelectorField source="businessArea" sortable={false} /> */}
    </Datagrid>
  </List>
)
