import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { ReferenceField, TextField } from 'react-admin'

const Field: FC<any> = ({ source, ...props }) => {
  if (
    props.record &&
    props.record[source] &&
    props.record['typeEventId'] === 6
  ) {
    return (
      <ReferenceField
        {...props}
        label="campaign"
        source={source}
        reference="Campaigns"
      >
        <TextField source="name" />
      </ReferenceField>
    )
  } else {
    return <TextField source="eventName" {...props} />
  }
}

Field.defaultProps = {
  addLabel: true,
  link: false,
  label: 'Name',
  source: 'campaignId',
}

Field.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  addLabel: PropTypes.bool,
  link: PropTypes.bool,
}

export default Field
