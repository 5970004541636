import english from 'ra-language-english'

const { ra } = english

export default {
  ra: {
    ...ra,
    validation: {
      ...ra.validation,
      string: 'Only A...Z, a...z, 0...9, -, _ are allowed',
      oneCharacter: 'Only 1 character is allowed',
      endDate:'The end date must be greater than the initial date',
      unique: '%{value} already used',
      url: 'Must be a correct url',
    },
    notification: {
      ...ra.notification,
      copied: 'Copied to clipboard',
      regenerateCode: {
        send: 'Regenerating code. Please wait...',
        end: 'Code regenerated :)'
      },
    },
  },
  resources: {
    CampaignURLs: {
      fields: {
        label: 'Campaign (Labels)',
        labelId: 'Campaign (Labels)',
        websiteURL: 'Website URL',
        campaignURLsSource: 'Source',
        campaignURLsMedium: 'Medium',
      },
    },
  },
}
