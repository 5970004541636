import React, { useState, useEffect } from 'react'
import { useDataProvider } from 'ra-core'
import {
  Button,
  useRedirect,
  useRefresh,
  useNotify,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { Delete as DeleteIcon } from '@material-ui/icons'
import ActionDelete from '@material-ui/icons/Delete'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

export default withStyles(styles)(props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [modal, setModal] = useState(false)
  const dataProvider = useDataProvider()
  const [records, setRecords] = useState(null)
  const [enabled, setEnabled] = useState(false)

  const handleModal = () => {
    setModal(!modal)
  }

  useEffect(() => {
    fetchAllImported()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(records)) {
      setEnabled(true)
    }
  }, [records])

  const fetchAllImported = async () => {
    const { data } = await dataProvider.getList('Events', {
      filter: { isImport: props.record.isImport },
    })
    setRecords(data)
  }

  const deleteAllImported = async () => {
    const ids = records.map(item => +item.id)
    await dataProvider.deleteMany('Events', {
      ids: ids,
    })

    setRecords(null)
    handleModal()
    success()
  }

  const success = () => {
    notify(`Deleted records`, 'success')
    redirect(props.basePath)
    refresh()
  }

  return (
    <>
      <Button
        label="DELETE"
        onClick={handleModal}
        className="custom-modal-button-color"
      >
        <DeleteIcon />
      </Button>
      <Dialog
        fullWidth
        open={modal}
        onClose={handleModal}
        aria-label="Are you sure?"
      >
        <DialogTitle>
          Are you sure you want to delete this entity?
        </DialogTitle>
        {enabled ? (
          <>
            <DialogContent>
              This will delete {records && records.length} records
              with the same import.
              <p>{props.record.isImport}</p>
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleModal}>
                <IconCancel />
              </Button>
              <Button
                onClick={deleteAllImported}
                label={'ra.action.delete'}
                className="custom-modal-button-color"
                key="button"
              >
                <ActionDelete />
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        )}
      </Dialog>
    </>
  )
})
