import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'
// import useBusinessAreaId from '../../utils/useBusinessAreaId'

import { SelectorInput, DateInput, AutocompleteCustom, AutocompleteCustomFrom
} from '../../inputs'

import { useWithMedia, useWithCampaign, useIntervalDateValidator
} from '../../utils'

import { Tooltip
} from '../../buttons'


export default props => {
  const intervalDateValidator = useIntervalDateValidator()
  const withMedia = useWithMedia()
  const withCampaign = useWithCampaign()
  // const businessAreaId = useBusinessAreaId()
  const row = { display: 'flex', alignItems: 'baseline', width: '100%' }
  const row2 = { display: 'flex', alignItems: 'baseline', width: '350px' }

  return (
    <Create {...props}>
      <SimpleForm>
        {/* {businessAreaId === '5' && (
          <div style={row2}>
            <SelectorInput source="businessArea" validate={required()} resettable fullWidth/>
            <Tooltip text="Specify the businessAreaId of event"/>
          </div>
         )}  */}
        <div style={row2}>
          <DateInput source="initialDate" validate={required()} fullWidth/> 
          <Tooltip text="Date when the event started"/>
        </div>
        <div style={row2}>
          <DateInput source="endDate" validate={[intervalDateValidator, required()]} fullWidth/>
          <Tooltip text="Date when the event finished"/>
        </div>
        <div style={row2}>
          <SelectorInput source="typeEvent" validate={required()} resettable fullWidth/>
          <Tooltip text="Specify the type of event occurred"/>
        </div>
        <div style={row}>
          <FormDataConsumer>
          {({ formData }) => (
              withCampaign(formData.typeEventId) ? (
                <>
                  <SelectorInput source="campaign" resettable optional fullWidth validate={required()}/>
                  <Tooltip text="If the type of event is a campaign, select the name of the campaign from the list"/>
                </>
              ):(
                <>
                  <TextInput source="eventName" fullWidth validate={required()}/>
                  <Tooltip text="Name of the event"/>
                </>
              )
          )}
          </FormDataConsumer>
        </div>
        <div style={row}>
          <TextInput source="eventDescription" fullWidth multiline/>
          <Tooltip text="Description of the event"/>
        </div>
        <div style={row}>
          <TextInput source="eventObjective" fullWidth multiline/>
          <Tooltip text="Main objective of the event"/>
        </div>
        <div style={row}>
          <AutocompleteCustom  source="countriesIds" reference="Countries" label="Countries" validate={required()} multiple fullWidth/>
          <Tooltip text="Country(ies) where the event occurred"/>
        </div>
        <div style={row}>
          <AutocompleteCustom parse={array => array} source="destinationsIds" reference="Destinations" label="Destinations" validate={required()} multiple fullWidth/>
          <Tooltip text="Site where the event occurred or was linked to"/>
        </div>
        <FormDataConsumer>
        {({ formData }) => withMedia(formData.typeEventId) && (
          <>
            <div style={row2}>
              <SelectorInput source="mediaType" resettable optional fullWidth/>
              <Tooltip text="If the type of event is PR, select the type of media (e.g. online, print, ...)"/>
            </div>
            <div style={row2}>
              <TextInput source="mediaSource" fullWidth />
              <Tooltip text="If the type of event is PR, specify the media source (e.g. Instyle)"/>
            </div>
            <div style={row2}>
              <BooleanInput label="Affiliate Link" source="affiliateLink" />
              <Tooltip text="If the type of event is PR, specify if there was an affiliate link (e.g. yes or no)"/>
            </div>
          </>
        )}
        </FormDataConsumer>
        <div style={row}>
          <AutocompleteCustom parse={array => array} source="brandsIds" reference="Brands" label="Brands" multiple fullWidth/>
          <Tooltip text="Brand/s related to the event, if applicable"/>
        </div>
        <div style={row}>
          <AutocompleteCustomFrom parse={array => array} source="products" from="brands" label="Products" singular multiple fullWidth/>
          <Tooltip text="Product/s related to the event, if applicable"/>
        </div>
      </SimpleForm>
    </Create>
  )
}
