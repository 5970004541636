import React, { FC } from 'react'
import { TextInput, TextInputProps } from 'react-admin'

const Input:FC<TextInputProps> = props => (
  <TextInput
    {...props}
  />
)

Input.defaultProps = {
  source: 'model',
}

export default Input
