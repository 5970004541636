import React, { useState } from 'react'
import { Button, useRefresh, useNotify } from 'react-admin'
import { useDataProvider } from 'ra-core'
import ExcelJS from 'exceljs'
import { v4 as uuidv4 } from 'uuid'
import SaveIcon from '@material-ui/icons/Save'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  form: {
    marginTop: 30,
    marginBottom: 30,
  },
  loader: {
    textAlign: 'center',
  },
  customModal: {
    position: 'fixed',
    zIndex: '9999999',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  customModalContent: {
    backgroundColor: '#fefefe',
    margin: '15% auto',
    padding: 20,
    border: '1px solid #888',
    width: 655,
    borderRadius: 4,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
  },
})

const UploadExcelButton = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const handleModal = () => {
    setModal(!modal)
    setLoading(false)
    setErrors([])
  }

  const submitForm = e => {
    e.preventDefault()
    const wb = new ExcelJS.Workbook()
    const reader = new FileReader()
    var file = document.getElementById('files')

    if (file.files[0]) {
      reader.readAsArrayBuffer(file.files[0])
      reader.onload = () => {
        const buffer = reader.result
        wb.xlsx.load(buffer).then(workbook => {
          var values = []
          workbook.eachSheet(sheet => {
            sheet.eachRow(row => {
              values.push(row.values)
            })
          })
          upload(values)
        })
      }
    }
  }

  const upload = async values => {
    setLoading(true)
    const data = transformValuesToData(values)
    await checkErrors(data)

    if (errors.length) {
      setLoading(false)
    } else {
      createInDataProvider(data)
      setLoading(false)
      setModal(!modal)
    }
  }

  const transformValuesToData = values => {
    values = removeEmtyValues(values)

    const keysToArray = [
      'brandsIds',
      'destinationsIds',
      'productsIds',
      'countriesIds',
    ]
    const uuid = uuidv4()
    const keys = getKeys(values)

    let d = {}
    return values.map(v => {
      d = {
        isImport: uuid,
      }
      v.forEach((val, j) => {
        if (val) {
          if (keys[j].includes('Ids')) {
            keysToArray.forEach(a => {
              if (keys[j].includes(a)) {
                if (!Array.isArray(d[a])) {
                  d[a] = []
                }
                if (a === 'countriesIds') {
                  d[a].push(+val.replace(/(^\d+)(.+$)/i, '$1'))
                } else {
                  val = val.split('/')[0].trim()
                  d[a].push(val)
                }
              }
            })
          } else if (keys[j].includes('Id')) {
            d[keys[j]] = +val.replace(/(^\d+)(.+$)/i, '$1')
          } else {
            if (val === 'Yes') {
              val = true
            } else if (val === 'No') {
              val = false
            }
            d[keys[j]] = val
          }
        }
      })
      return d
    })
  }

  const removeEmtyValues = values =>
    values
      .map(value => (value.join('').length > 0 ? value : undefined))
      .filter(x => x !== undefined)

  const getKeys = values => {
    let keys = values.shift()
    const res = keys.map(k =>
      k
        .replace('*', '')
        .replace('(only PR)', '')
        .replace('(only campaign)', '')
        .trim(),
    )
    return res
  }

  const checkErrors = async data => {
    errors.length = 0
    const checkIdKeys = [
      { name: 'Countries', field: 'countriesIds' },
      { name: 'Destinations', field: 'destinationsIds' },
      { name: 'TypeEvents', field: 'typeEventId' },
      { name: 'Campaigns', field: 'campaignId' },
      { name: 'MediaTypes', field: 'mediaTypeId' },
      { name: 'Brands', field: 'brandsIds' },
      { name: 'Products', field: 'productsIds' },
    ]
    const databaseValues = await fetchData(checkIdKeys)

    await data.forEach(async (d, i) => {
      checkRequiredForPR(d, i)
      checkRequired(d, i)
      checkDate(d, i)
      checkCampaign(d, i)
      checkRepeat(d, i)
      checkRelations(d, i, databaseValues)
    })
  }

  const checkRelations = (d, i, databaseValues) => {
    const products = databaseValues[6]

    d.productsIds &&
      d.productsIds.forEach(id => {
        const index = products.findIndex(p => p.id === id)
        if (index !== -1) {
          const product = products[index]
          if (d.brandsIds) {
            if (product.brandId) {
              const found = d.brandsIds.find(
                brandId => brandId === product.brandId,
              )
              if (!found) {
                errors.push(
                  `Line ${i + 2}: brandsIds: ${
                    d.brandsIds
                  } not related to productId: ${
                    product.id
                  }. Product is related to brandIds ${
                    product.brandId
                  }`,
                )
              }
            } else {
              errors.push(
                `Line ${i + 2}: productId ${
                  product.id
                } is not related to any brand`,
              )
            }
          } else {
            errors.push(
              `Line ${i + 2}: productId ${
                product.id
              } must have a Brand`,
            )
          }
        }
      })
  }

  const checkDate = async (d, i) => {
    const initial = new Date(d.initialDate)
    const end = new Date(d.endDate)
    if (!Date.parse(d.initialDate)) {
      errors.push(
        `Line ${
          i + 2
        }: Initial date has not a correct format. The format has to be like (30/01/2022)`,
      )
    }
    if (!Date.parse(d.endDate)) {
      errors.push(
        `Line ${
          i + 2
        }: End date has not a correct format. The format has to be like (30/01/2022)`,
      )
    }
    if (+initial > +end) {
      errors.push(
        `Line ${i + 2}: Initial date cant be greater than End date`,
      )
    }
  }

  const checkRequired = async (d, i) => {
    const keys = [
      'initialDate',
      'endDate',
      'countriesIds',
      'destinationsIds',
      'typeEventId',
    ]
    keys.forEach(k => {
      if (!d[k]) {
        errors.push(`Line ${i + 2}: Required field ${k}`)
      }
    })
  }

  const checkRequiredForPR = async (d, i) => {
    if (d['typeEventId'] === 1 && !('affiliateLink' in d)) {
      errors.push(`Line ${i + 2}: Required field affiliateLink`)
    }
  }

  const checkCampaign = async (d, i) => {
    if (d.typeEventId === 6 && !d.campaignId) {
      errors.push(`Line ${i + 2}: Required field campaignId`)
    }
  }

  const checkRepeat = async (d, i) => {
    Object.keys(d).forEach(k => {
      if (k.includes('Ids')) {
        if (checkForDuplicates(d[k])) {
          errors.push(`Line${i + 2}: Id ${d.id} ${k} has duplicates`)
        }
      }
    })
  }
  function checkForDuplicates(array) {
    return new Set(array).size !== array.length
  }

  const fetchData = async fields => {
    let data = await fields.map(async source => {
      const { data } = await dataProvider.getList(source.name, {
        pagination: { page: 1, perPage: 999999 },
      })
      return data
    })
    return await Promise.all(data)
  }

  const createInDataProvider = async data => {
    const reportItems = []
    let error = null
    await data.forEach(async d => {
      try {
        const response = await dataProvider.create('Events', {
          data: d,
        })
        reportItems.push({
          value: null,
          success: true,
          response: response,
        })
      } catch (e) {
        setErrors(errors.push(e))
        error = e
      }
    })
    !error && (await success())
    return reportItems
  }

  const success = async () => {
    notify('Uploaded records', 'success')
    await setTimeout(() => {
      refresh()
    }, 1000)
  }

  return (
    <>
      <Button label="Upload" onClick={handleModal}>
        <CloudUploadIcon />
      </Button>
      {modal && (
        <div className={classes.customModal}>
          <div className={classes.customModalContent}>
            <h2>Upload</h2>
            <p>The file format has to be .xlsx</p>
            <div className={classes.form}>
              <input
                type="file"
                id="files"
                name="files"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>

            <div className={classes.loader}>
              {loading && <CircularProgress />}
            </div>
            {errors.length > 0 && (
              <div className="uploadErrors">
                <h3>Errors</h3>
              </div>
            )}
            {errors.length > 0 &&
              errors.map((e, i) => <div key={`err${i}`}>{e}</div>)}

            <div className={classes.btnContainer}>
              <Button label="CANCEL" onClick={handleModal} />
              <Button label="UPLOAD" onClick={submitForm}>
                <SaveIcon />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadExcelButton
