import React, { FC } from 'react'
import get from 'lodash/get'
import { ChipField, ChipFieldProps } from 'react-admin'

interface IChipFieldProps extends ChipFieldProps {
  [key: string]: any;
  source: string;
  label: string;
  id: string;
}

export const CHOICES = [
  { id: 'ROLE_ADMIN', name: 'Admin', platform: ['labelling', 'events'] },
  { id: 'ROLE_OWNER', name: 'Owner', platform: ['labelling'] },
  { id: 'ROLE_COSTS', name: 'Costs', platform: ['labelling'] },
  { id: 'ROLE_CREATOR', name: 'Creator', platform: ['events'] },
  { id: 'ROLE_VIEW', name: 'View', platform: ['labelling', 'events'] },
  { id: 'ROLE_MANAGER', name: 'Manager', platform: ['events'] },
]

const Field:FC<IChipFieldProps> = ({ record, label, ...props }) => {
  const value = get(record, props.source)[label.toLowerCase()]
  const choice = CHOICES.find(c => c.id === value)
  
  if (choice === undefined) return null

  return <ChipField record={{ [props.source]: choice.name, id: choice.id }} {...props} />
}

Field.defaultProps = {
  addLabel: true,
  source: 'permits',
}

export default Field
