import React,{useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  AutocompleteArrayInput, 
  AutocompleteInput, 
} from 'react-admin'
import {
    useDataProvider
  } from 'ra-core'

  import {
    TextField,
  } from '@material-ui/core'

import { useFormState } from 'react-final-form'
import { capitalize } from 'lodash'

const Input = ({multiple, label, source, from, singular, ...props}) => {
    const dataProvider = useDataProvider()
    const { values } = useFormState()
    const [choices, setChoices] = useState([])

    useEffect(() => {
      if(values[`${from}Ids`]){
        getChoices(values[`${from}Ids`])
      }
    }, [values[`${from}Ids`]])// eslint-disable-line react-hooks/exhaustive-deps

    const getChoices = async (ids) => {
      let res = await Promise.all(ids.map(async id => {
        let  filter = {}
        singular ? filter[`${removeLastLetter(from)}Id`] = id : filter[`${from}Ids`] = id
        const response = await dataProvider
        .getList(capitalize(source), {
          pagination: { page: 1, perPage: 9999999 },
          filter: filter,
        })
        return response.data
      }))
      let choices = res.flat()
      setChoices(choices)
    }

    const removeLastLetter = (string) => {
        return string.slice(0, -1)
    }

    const C = multiple ? AutocompleteArrayInput : AutocompleteInput

    return (
      <>
        {choices.length===0 ? (
          <TextField label={capitalize(source)} variant="filled" margin="normal" InputProps={{ readOnly: true, defaultValue: `No choices found for selected ${from}` }} fullWidth />
        ): (
          <C choices={choices } source={`${source}Ids`}  optionText="name" fullWidth {...props}/>
        )}  
      </>
    )
}

Input.defaultProps = {
    addLabel: true,
    perPage: 999999,
    multiple: false,
    singular: false
  }

Input.propTypes = {
    source: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    optionText: PropTypes.string,
    perPage: PropTypes.number,
    multiple: PropTypes.bool,
    label: PropTypes.string.isRequired,
    addLabel: PropTypes.bool,
}

export default Input
