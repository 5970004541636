import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'

import {
  DateTimeField,
  ModelField,
  TypeField,
  UserField,
} from '../../fields'

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateTimeField source="createdAt" />
      <ModelField />
      <TypeField />
      <UserField source="createdBy" />
      <TextField source="params" />
    </SimpleShowLayout>
  </Show>
)
