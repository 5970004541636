import React from 'react'
import {
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin'

const FilterSelector = ({ multiple, ...props }) => {
  const W = multiple ? ReferenceArrayInput : ReferenceInput
  const C = multiple ? SelectArrayInput : SelectInput

  multiple && (props.label = props.reference)

  return (
    <W {...props} sort={{field: "name", order: 'ASC'}}>
      <C optionText="name" />
    </W>
  )
}

FilterSelector.defaultProps = {
  addLabel: true,
  multiple: false,
  perPage: 999999,
}

export default FilterSelector
