import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin'

import { CodeField } from '../../fields'

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="title" />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    title="Examples"
    filters={<Filters />}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <CodeField source="title" />
    </Datagrid>
  </List>
)
