import React from 'react'
import {
  DeleteButton,
  Edit,
  ListButton,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
  usePermissions,
} from 'react-admin'

import {
  CodeField,
  SelectorField,
  DateField,
  UserField,
} from '../../fields'

import { useStringValidator } from '../../utils'

const ModelToolbar = props => {
  const { permissions } = usePermissions()

  return (
    <Toolbar {...props}>
      {['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_COSTS'].includes(
        permissions?.labelling,
      ) && (
        <SaveButton
          transform={data => {
            if (data.initialDate === '') data.initialDate = null
            if (data.endDate === '') data.endDate = null
            return data
          }}
          submitOnEnter={false}
          redirect="edit"
        />
      )}
      <ListButton />
      {['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_COSTS'].includes(
        permissions?.labelling,
      ) && <DeleteButton />}
    </Toolbar>
  )
}

export default props => {
  const stringValidator = useStringValidator()

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ModelToolbar />}>
        <CodeField source="url" />
        <TextField fullWidth source="websiteURL" />
        <SelectorField source="label" optionText="code" />
        <SelectorField source="source" />
        <SelectorField source="medium" />
        <TextField fullWidth source="term" />
        <TextField fullWidth source="content" />
        <DateField source="createdAt" />
        <UserField source="createdBy" />
      </SimpleForm>
    </Edit>
  )
}
