import React, { FC } from 'react'
import PropTypes from 'prop-types'
import {
  required,
  ReferenceInput,
  SelectInput,
  ReferenceInputProps,
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

interface IReferenceInputProps extends ReferenceInputProps {
  validate?: any
}

const Input: FC<IReferenceInputProps> = ({
  source,
  optionText,
  optional,
  ...props
}) => {
  const ref = useGetReferenceBySource(source)

  return (
    <ReferenceInput
      source={source === 'createdBy' ? source : `${source}Id`}
      sort={{ field: optionText, order: 'ASC' }}
      validate={!optional && [required()]}
      {...props}
      reference={ref}
    >
      <SelectInput optionText={optionText} {...props} />
    </ReferenceInput>
  )
}

Input.defaultProps = {
  optionText: 'name',
  optional: false,
  parse: (v: string | null) => (v ? parseInt(v, 10) : null),
  format: (v: string | null) => (v ? String(v) : null),
  perPage: 1000,
}

Input.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  optional: PropTypes.bool,
  parse: PropTypes.func,
  format: PropTypes.func,
  perPage: PropTypes.number,
}

export default Input
