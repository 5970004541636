import React from 'react'
import Tooltip from "@material-ui/core/Tooltip"
import HelpIcon from "@material-ui/icons/Help"

export default ({text}) => (
    <Tooltip title={text} style={{marginLeft: 20, width: 20}}>
        <HelpIcon />
    </Tooltip>
)



