import React from 'react'

import { AutocompleteCustom } from './index'

const Input = (
  props: JSX.IntrinsicAttributes & {
    [x: string]: any
    multiple: any
    field: any
  },
) => <AutocompleteCustom {...props} />

Input.defaultProps = {
  source: 'labelId',
  reference: 'Labels',
  field: 'code',
  addLabel: false,
  fullWidth: true,
  perPage: 100,
}

export default Input
