import React from 'react'
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  usePermissions,
} from 'react-admin'

import { SelectorInput } from '../../inputs'

import { useStringValidator } from '../../utils'

export default props => {
  const stringValidator = useStringValidator()
  const { permissions } = usePermissions()
  const isAdmin = ['ROLE_ADMIN'].includes(permissions?.events)

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          disabled
          source="short"
          validate={[required(), stringValidator]}
        />
        <TextInput
          disabled={!isAdmin}
          source="name"
          validate={required()}
        />
        <SelectorInput
          disabled={!isAdmin}
          source="brand"
          parse={string => string}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  )
}
