import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput, ReferenceArrayInput } from 'react-admin'

const AutocompleteCustom = ({ multiple, field, ...props }) => {
    const W = multiple ? ReferenceArrayInput : ReferenceInput
    const C = multiple ? AutocompleteArrayInput : AutocompleteInput

    return (
        <W sort={{field , order: 'ASC'}} filterToQuery={searchText => ({ [field]: [searchText] })} {...props}>
            <C optionText={field} />
        </W>
    )
}

AutocompleteCustom.defaultProps = {
    parse: array => array && Array.isArray(array) ? array?.map(v => parseInt(v, 10)) : array,
    format: array => array && Array.isArray(array) ? array?.map(v => v ? String(v) : null) : array,
    addLabel: true,
    perPage: 999999,
    multiple: false,
    allowEmpty: false,
    field: "name",
}

AutocompleteCustom.propTypes = {
    source: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    addLabel: PropTypes.bool,
    perPage: PropTypes.number,
    field: PropTypes.string,
}

export default AutocompleteCustom
