import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Button } from 'react-admin'
import { useDataProvider } from 'ra-core'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'

export default () => {
  const dataProvider = useDataProvider()

  const exportExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Mysheet')
    // const keys = [
    //   'initialDate*',
    //   'endDate*',
    //   'typeEventId*',
    //   'eventName',
    //   'eventDescription',
    //   'eventObjective',
    //   'countriesIds*',
    //   'destinationsIds1*',
    //   'destinationsIds2',
    //   'destinationsIds3',
    //   'campaignId (only campaign)',
    //   'mediaTypeId (only PR)',
    //   'mediaSource (only PR)',
    //   'affiliateLink (only PR)',
    //   'brandsIds1',
    //   'brandsIds2',
    //   'brandsIds3',
    //   'brandsIds4',
    //   'productsIds1',
    //   'productsIds2',
    //   'productsIds3',
    //   'productsIds4',
    // ]

    // Keys change
    const keys = [
      'campaignLevelId*',
      'countryId*',
      'channelSourceId*',
      'campaignTypeId*',
      'destinationId*',
      'agencyId*',
      'campaignId*',
      'objectiveId*',
      'brandsIds1',
      'brandsIds2',
      'description',
      'amount',
      'currencyId',
      'impressions',
      'initialDate',
      'endDate',
    ]
 
    worksheet.columns = header(keys)

    for (let i = 0; i < 1000; i++) {
      worksheet.addRow(emptyRow(keys))
    }

    const fields = [
      { name: 'CampaignLevels', pos: 1 },
      { name: 'Countries', pos: 2 },
      { name: 'ChannelSources', pos: 3 },
      { name: 'CampaignTypes', pos: 4 },
      { name: 'Destinations', pos: 5 },
      { name: 'Agencies', pos: 6 },
      { name: 'Campaigns', pos: 7 },
      { name: 'Objectives', pos: 8 },
      { name: 'Brands', pos: [9, 10] },
      { name: 'Currencies', pos: 13 },
    ]

    // const fields = [
    //   { name: 'TypeEvents', pos: 3 },
    //   { name: 'Countries', pos: 7 },
    //   { name: 'Destinations', pos: [8, 9, 10] },
    //   { name: 'Campaigns', pos: 11 },
    //   { name: 'MediaTypes', pos: 12 },
    //   { name: 'Brands', pos: [15, 16, 17, 18] },
    //   { name: 'Products', pos: [19, 20, 21, 22] },
    // ]
    const dropdowns = await fetchData(fields)

    var sheet = workbook.getWorksheet('Mysheet')
    for (var i = 1; i <= sheet.actualRowCount; i++) {
      for (var j = 1; j <= sheet.actualColumnCount; j++) {
        if (dropdowns[j] && i > 1) {
          sheet.getRow(i).getCell(j).dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [dropdowns[j]],
          }
        }
        // if(j===14){
        //   sheet.getRow(i).getCell(j).dataValidation = {
        //     type: 'list',
        //     allowBlank: false,
        //     formulae: ['"Yes,No"'],
        //   }
        // }
      }
    }

    workbook.xlsx
      .writeBuffer()
      .then(buffer =>
        saveAs(
          new Blob([buffer]),
          `ISDIN_DigitalTools_uploadEvents_${Date.now()}.xlsx`,
        ),
      )
      .catch(err => console.log('Error writing excel export', err))
  }

  const emptyRow = keys => {
    let emptyRow = {}
    for (const key of keys) {
      emptyRow[key] = ''
    }
    return emptyRow
  }
  const header = keys => {
    return keys.map(k => ({ header: k, key: k, width: 32 }))
  }

  const fetchData = async fields => {
    let data = await fields.map(async source => {
      const { data } = await dataProvider.getList(source.name, {
        pagination: { page: 1, perPage: 9999 },
      })
      return data
    })
    data = await Promise.all(data)

    let res = {}
    fields.forEach((f, i) => {
      if(Array.isArray(f.pos)){
        f.pos.forEach(p => {
          res[p] =
          '"' +
          data[i].reduce(
            (a, b) => a + b.id + ' / ' + b.name + ',',
            '',
          ) +
          '"'
        })
      }else{
        res[f.pos] =
        '"' +
        data[i].reduce(
          (a, b) => a + b.id + ' / ' + b.name + ',',
          '',
        ) +
        '"'
      }
    })
    return res
  }

  return (
    <Button label="Download Template" onClick={exportExcel}>
      <GetAppIcon />
    </Button>
  )
}
