import React, { FC } from 'react'
import {
  ReferenceField,
  ReferenceFieldProps,
  FunctionField,
} from 'react-admin'
import PropTypes from 'prop-types'

const Field: FC<ReferenceFieldProps> = ({ reference, ...props }) => {
  return (
    <ReferenceField reference="Users" {...props}>
      <FunctionField
        render={(record: any) => {
          return (
            <span>
              {record.name} {record.surname}
            </span>
          )
        }}
      />
    </ReferenceField>
  )
}

Field.defaultProps = {
  addLabel: true,
  link: 'show',
}

Field.propTypes = {
  addLabel: PropTypes.bool,
  link: PropTypes.string,
  source: PropTypes.string.isRequired,
}

export default Field
