import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanInput
} from 'react-admin'

import {
  useMaxLengthValidator,
  useStringValidator,
  useUniqueValidator,
} from '../../utils'

import {
  DateInput,
} from '../../inputs'

export default props => {
  const maxLengthValidator = useMaxLengthValidator(props.resource)
  const stringValidator = useStringValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'short', props.id)
  const { permissions } = usePermissions()
  const isAdmin =['ROLE_ADMIN'].includes(permissions?.events) || ['ROLE_ADMIN'].includes(permissions?.labelling)
  const isManager =['ROLE_MANAGER'].includes(permissions?.events) || ['ROLE_MANAGER'].includes(permissions?.labelling)

  const ToolbarActions = props => {
    return(
      <>
        { isAdmin
        && 
          <Toolbar {...props}>
              <SaveButton/>
              <DeleteButton />
          </Toolbar>
        }
        { isManager
        && 
          <Toolbar {...props}>
              <SaveButton/>
          </Toolbar>
        }
      </>
  )}

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ToolbarActions />}>
        <DateInput disabled={!isAdmin} source="date" validate={required()} />
        <TextInput disabled={!isAdmin} source="short" validate={[required(), maxLengthValidator, stringValidator, uniqueValidator]} />
        <TextInput disabled={!isAdmin} source="name" validate={required()} />
        <BooleanInput disabled={!(isAdmin || isManager)} source="active" />
      </SimpleForm>
    </Edit>
  )
}
