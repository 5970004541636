import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  SaveButton,
  DeleteButton,
  Toolbar
} from 'react-admin'

import {
  useMaxLengthValidator,
  useStringValidator,
  useUniqueValidator,
  hasShortKey
} from '../../utils'

export default props => {
  const maxLengthValidator = useMaxLengthValidator(props.resource)
  const stringValidator = useStringValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'short', props.id)
  const { permissions } = usePermissions()
  const isAdmin =['ROLE_ADMIN'].includes(permissions?.labelling)

  const ToolbarActions = props => {
    return(
      <>
        { isAdmin
        && 
          <Toolbar {...props}>
              <SaveButton/>
              <DeleteButton />
          </Toolbar>
        }
      </>
  )}

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ToolbarActions />}>
        {
          hasShortKey(props.resource) &&
          <TextInput disabled source="short" validate={[required(), maxLengthValidator, stringValidator, uniqueValidator]} />
        }
        <TextInput disabled={!isAdmin} source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
