/* eslint-disable no-param-reassign */
import React, { FC } from 'react'
import { TextField, TextFieldProps } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { IRecord } from '../types'

interface ITextFieldProps extends TextFieldProps {
  [key: string]: any;
  source: string;
  record: IRecord;
}

const useStyles = makeStyles({
  red: { color: '#EC5B55', fontWeight: 'bold' },
  blue: { color: '#4192F7', fontWeight: 'bold' },
  green: { color: '#74CF95', fontWeight: 'bold' },
})

export const CHOICES = [
  { id: 'insert', name: 'INSERT', color: 'green' },
  { id: 'update', name: 'UPDATE', color: 'blue' },
  { id: 'delete', name: 'DELETE', color: 'red' },
]

const Field:FC<ITextFieldProps> = ({ source, record, ...props }) => {
  const classes = useStyles()
  const choise = record && CHOICES.find(c => c.id === record[source])
  if (choise === undefined || record === null) return null

  props.record = {
    [source]: record ? choise.name : '',
  }
  props.source = source

  return <TextField className={classes[choise.color as 'red' | 'blue' | 'green']} {...props} />
}

Field.defaultProps = {
  source: 'type',
  addLabel: true,
}

export default Field
