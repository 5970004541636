import React, { cloneElement } from 'react'
import {
  Datagrid,
  List,
  Filter,
  TextInput,
  TextField,
  usePermissions,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin'

import { FilterSelector, FilterSelectorUser, LabelsInput } from '../../inputs'
import {
  CodeField,
  SelectorField,
  DateField,
  UserField,
} from '../../fields'

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions()
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {[
        'ROLE_ADMIN',
        'ROLE_CREATOR',
        'ROLE_OWNER',
        'ROLE_MANAGER',
        'ROLE_COSTS',
      ].includes(permissions?.labelling) && (
        <>
          <CreateButton basePath={basePath} />
        </>
      )}
    </TopToolbar>
  )
}
ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
}

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="url" alwaysOn />
      <LabelsInput />
      <FilterSelector
        source="sourceId"
        reference="CampaignURLsSources"
        resettable
      />
      <FilterSelector
        source="mediumId"
        reference="CampaignURLsMediums"
        resettable
      />
      <TextInput source="term" />
      <TextInput source="content" />
      <FilterSelectorUser source="createdBy" reference="Users" />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    title={props.options.label}
    filters={<Filters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <CodeField sortable={false} source="url" />
      <SelectorField source="label" sortable={false} optionText="code" />
      <SelectorField source="source" sortable={false} />
      <SelectorField source="medium" sortable={false} />
      <TextField source="term"/>
      <TextField source="content"/>
      <DateField source="createdAt" />
      <UserField source="createdBy" />
    </Datagrid>
  </List>
)
