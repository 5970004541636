import React, { FC } from 'react'
import { DateFieldProps } from 'react-admin'
import { DateField } from './index'

const Field: FC<DateFieldProps> = props => (
  <DateField showTime {...props} />
)

Field.defaultProps = {
  locales: 'en-GB',
  addLabel: true,
}

export default Field
