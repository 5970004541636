import React, { FC } from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayInputProps,
} from 'react-admin'

import { capitalize } from 'lodash'

import { useGetReferenceBySource } from '../utils'

const Input:FC<ReferenceArrayInputProps> = ({ source, optionText, ...props }) => {
  const ref = useGetReferenceBySource(source)
  return (
    <ReferenceArrayInput
      source={`${source}Ids`}
      label={capitalize(source)}
      sort={{ field: optionText, order: 'ASC' }}
      {...props}
      reference={ref}
    >
      <SelectArrayInput optionText={optionText} />
    </ReferenceArrayInput>
  )
}

Input.defaultProps = {
  optionText: 'name',
  parse: (array: string[] | null) => array?.map(v => parseInt(v, 10)),
  format: (array: string[] | null) => array?.map(v => v ? String(v) : null),
  perPage: 200,
}

Input.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  perPage: PropTypes.number,
}

export default Input
