import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin'

import {
  SelectorInput,
  LabelsInput,
} from '../../inputs'

import {
  useStringValidator,
  useUrlValidator,
} from '../../utils'

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
        transform={(data) => {
            if (data.initialDate === '') data.initialDate = null
            if (data.endDate === '') data.endDate = null
            return data
        }}
        submitOnEnter={false}
        />
    </Toolbar>
)

export default props => {
  const stringValidator = useStringValidator()
  const urlValidator = useUrlValidator()
  const websiteURLValidator = [required(), urlValidator]

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput fullWidth source="websiteURL" validate={websiteURLValidator} helperText="The full website URL (e.g. https://www.example.com)"/>
        <LabelsInput validate={[required()]} />
        <SelectorInput source="source" />
        <SelectorInput source="medium" />
        <TextInput source="term" validate={[stringValidator]} />
        <TextInput source="content" validate={[stringValidator]} />
      </SimpleForm>
    </Create>
  )
}
