import React, { cloneElement } from 'react'
import {
  Datagrid,
  List,
  Filter,
  TextInput,
  TextField,
  usePermissions,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin'

import { FilterSelector, FilterSelectorUser } from '../../inputs'
import {
  CodeField,
  SelectorField,
  AmountField,
  DateField,
  UserField,
} from '../../fields'
import {
  UploadExcelButtonLabels,
  ExportExcelButtonLabels,
} from '../../buttons'

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions()
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {[
        'ROLE_ADMIN',
        'ROLE_CREATOR',
        'ROLE_OWNER',
        'ROLE_MANAGER',
      ].includes(permissions?.labelling) && (
        <>
          <ExportExcelButtonLabels
            resource={resource}
            total={total}
          />
          <UploadExcelButtonLabels
            resource={resource}
            total={total}
          />
        </>
      )}
      {[
        'ROLE_ADMIN',
        'ROLE_CREATOR',
        'ROLE_OWNER',
        'ROLE_MANAGER',
        'ROLE_COSTS',
      ].includes(permissions?.labelling) && (
        <>
          <CreateButton basePath={basePath} />
        </>
      )}
    </TopToolbar>
  )
}
ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
}

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="code" alwaysOn />
      <FilterSelector
        source="campaignLevelId"
        reference="CampaignLevels"
        resettable
      />
      {/* <FilterSelector source="businessAreaId" reference="BusinessAreas"/> */}
      <FilterSelector
        source="channelSourceId"
        reference="ChannelSources"
        resettable
      />
      <FilterSelector
        source="countryId"
        reference="Countries"
        resettable
      />
      <FilterSelectorUser source="createdBy" reference="Users" />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    title={props.options.label}
    filters={<Filters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <CodeField sortable={false} />
      <DateField source="createdAt" />
      <UserField source="createdBy" />
      <AmountField />
      <TextField source="impressions" />
      <DateField source="initialDate" />
      <DateField source="endDate" />
      <SelectorField source="campaignLevel" sortable={false} />
      <SelectorField source="channelSource" sortable={false} />
      <SelectorField source="country" sortable={false} />
      {/* <SelectorField source="businessArea" sortable={false} /> */}
    </Datagrid>
  </List>
)
