import React, { FC } from 'react'

import { useNotify, TextFieldProps } from 'react-admin'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const Field: FC<TextFieldProps> = ({ source, record }) => {
  const notify = useNotify()
  const value = record && source && record[source]
  if (value === undefined) return null
  return (
    <div>
      {`${value} `}
      <CopyToClipboard
        text={value}
        onCopy={() => {
          notify('ra.notification.copied', 'success')
        }}
      >
        <FileCopyIcon
          color="action"
          style={{ marginLeft: 10, fontSize: 18 }}
        />
      </CopyToClipboard>
    </div>
  )
}

Field.defaultProps = {
  source: 'code',
  addLabel: true,
}

export default Field
