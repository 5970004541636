import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput
} from 'react-admin'

const Filters = props => {
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    filters={<Filters />}
    title={props.options.label}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="symbol" />
      <TextField source="name" />
    </Datagrid>
  </List>
)
