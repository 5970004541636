import React, { cloneElement } from 'react'
import {
  Datagrid,
  List,
  Filter,
  SearchInput,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  usePermissions,
  BooleanField,
  BooleanInput,
  downloadCSV,
  ExportButton,
} from 'react-admin'
import {
  DateInput,
  FilterSelector,
  AutocompleteCustom,
  FilterSelectorUser,
} from '../../inputs'
import {
  SelectorField,
  DateField,
  SelectorArrayField,
  EventNameField,
  UserField,
} from '../../fields'
import { UploadExcelButton, ExportExcelButton } from '../../buttons'
import jsonExport from 'jsonexport/dist'
import moment from 'moment'

const exporter = async (records, fetchRelatedRecords) => {
  let data = records
  const fields = [
    { name: 'TypeEvents', pos: 'typeEventId' },
    { name: 'Countries', pos: 'countriesIds' },
    { name: 'Destinations', pos: 'destinationsIds' },
    { name: 'BusinessAreas', pos: 'businessAreaId' },
    { name: 'MediaTypes', pos: 'mediaTypeId' },
    { name: 'Brands', pos: 'brandsIds' },
    { name: 'Products', pos: 'productsIds' },
    { name: 'Users', pos: 'createdBy' },
    { name: 'Users', pos: 'updatedBy' },
  ]
  let res = await Promise.all(
    fields.map(async field => {
      return await fetchRelatedRecords(records, field.pos, field.name)
    }),
  )

  console.log('RESS: ', res)

  fields.forEach((field, i) => {
    data = data.map(record => {
      let txt = ''
      if (record[field.pos] !== undefined) {
        if (typeof record[field.pos] === 'string') {
          txt = res[i][Number(record[field.pos])]?.name
        } else if (
          Array.isArray(record[field.pos]) &&
          record[field.pos].length > 0
        ) {
          record[field.pos].forEach(num => {
            txt = txt + ' ' + res[i][num]?.name
          })
        } else {
          txt = res[i][record[field.pos]]?.name
        }
      }
      const row = {
        ...record,
        [field.pos]: txt,
      }
      return row
    })
  })

  data.forEach(d => {
    d.initialDate = moment(new Date(d.initialDate)).format(
      'DD/MM/YYYY',
    )
    d.endDate = moment(new Date(d.endDate)).format('DD/MM/YYYY')
  })

  return jsonExport(
    data,
    {
      headers: ['id'],
    },
    (err, csv) => {
      downloadCSV(csv, 'events')
    },
  )
}

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions()
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      {['ROLE_ADMIN', 'ROLE_CREATOR', 'ROLE_MANAGER'].includes(
        permissions?.events,
      ) && (
        <>
          <ExportExcelButton resource={resource} total={total} />
          <UploadExcelButton resource={resource} total={total} />
          <CreateButton basePath={basePath} />
        </>
      )}
      <ExportButton />
    </TopToolbar>
  )
}
ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
}

const Filters = props => {
  return (
    <Filter {...props}>
      <FilterSelector source="typeEventId" reference="TypeEvents" />
      <SearchInput source="eventName" alwaysOn />
      <DateInput source="initialDate" />
      <DateInput source="endDate" />
      {/* <FilterSelector source="businessAreaId" reference="BusinessAreas"/> */}
      <FilterSelector
        source="countriesIds"
        reference="Countries"
        multiple
      />
      <FilterSelector
        source="destinationsIds"
        reference="Destinations"
        multiple
      />
      <AutocompleteCustom
        parse={array => array}
        source="brandsIds"
        reference="Brands"
        label="Brands"
        multiple
      />
      <AutocompleteCustom
        parse={array => array}
        source="productsIds"
        reference="Products"
        label="Products"
        multiple
      />
      <DateInput source="createdAt" />
      <FilterSelectorUser source="createdBy" reference="Users" />
      <BooleanInput label="Affiliate Link" source="affiliateLink" />
    </Filter>
  )
}

export default props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      filters={<Filters />}
      title={props.options.label}
      sort={{ field: 'initialDate', order: 'DESC' }}
      exporter={exporter}
      actions={<ListActions />}
    >
      <Datagrid rowClick="show">
        {/* <SelectorField source="businessArea" sortable={false} /> */}
        <DateField source="initialDate" />
        <DateField source="endDate" />
        <EventNameField />
        <SelectorField source="typeEvent" sortable={false} />
        <SelectorArrayField source="countries" sortable={false} />
        <SelectorArrayField source="destinations" sortable={false} />
        <SelectorArrayField source="brands" sortable={false} />
        <SelectorArrayField source="products" sortable={false} />
        <DateField source="createdAt" />
        <UserField source="createdBy" />
        <BooleanField source="affiliateLink" />
      </Datagrid>
    </List>
  )
}
