interface IAllVallues {
  initialDate: Date;
}

export default (required = false) => {
  return (value:Date | undefined, allValues:IAllVallues) => {
    if (!allValues.initialDate || !value) return undefined
    if (value < allValues.initialDate){
      return 'ra.validation.endDate'
    }
    if (required){
      return 'ra.validation.required'
    }
    return undefined
  }
}
