import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import buildGraphQLProvider, {
  buildQuery,
} from 'ra-data-graphql-simple'

import { useToken } from '../../utils'

const myBuildQuery = introspection => (
  fetchType,
  resource,
  params,
) => {
  if(resource === 'CampaignURLs' && fetchType === 'GET_MANY_REFERENCE'){
    if(params.id) params.id = Number(params.id)
  }
  const builtQuery = buildQuery(introspection)(
    fetchType,
    resource,
    params,
  )

  return builtQuery
}

export default callback => {
  const [getToken, deleteToken] = useToken()
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${getToken()}`,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, response, operation, forward }) => {
  if (response) response.errors = null
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      switch (err.extensions.code) {
        default:
          console.log('error -----> ', err)
          break
        case "UNAUTHENTICATED":
          deleteToken()
          operation.setContext({
            headers: {},
          })
      }
    })
    return forward(operation)
  }
  return null
})

  const client = new ApolloClient({
    link: ApolloLink.from([
      authLink,
      errorLink,
      new HttpLink({
        uri: `${process.env.REACT_APP_API_URL}/graphql`,
      }),
    ]),
    cache: new InMemoryCache(),
    // name: 'labelling-client',
    connectToDevTools: true,
  })
  buildGraphQLProvider({ client, buildQuery: myBuildQuery })
    .then(callback)
    .catch(callback)
}
