import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  SaveButton,
  DeleteButton,
  Toolbar
} from 'react-admin'

import {
  useOneCharacterValidator,
  useUniqueValidator,
} from '../../utils'

export default props => {
  const oneCharactherValidator = useOneCharacterValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'symbol', props.id)
  const { permissions } = usePermissions()
  const isAdmin =['ROLE_ADMIN'].includes(permissions?.labelling)

  const ToolbarActions = props => {
    return(
      <>
        { isAdmin
        && 
          <Toolbar {...props}>
              <SaveButton/>
              <DeleteButton />
          </Toolbar>
        }
      </>
  )}

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ToolbarActions />}>
        <TextInput disabled={!isAdmin} source="symbol" validate={[required(), oneCharactherValidator, uniqueValidator]} />
        <TextInput disabled={!isAdmin} source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
