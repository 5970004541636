import { useGetOne } from 'react-admin'

import { defaultId } from '../models/config'

import hasShortKey from './hasShortKey'

interface IAllVallues {
  channelSourceId: number;
}

export default (key:string) => {
  const { data } = useGetOne('Configs', defaultId)

  if (data === undefined) return false

  if (key === 'description' ) {
    return (value:string, allValues:IAllVallues) => {
      const max = allValues.channelSourceId === 9 ? 15 : 40
      return value && value.length <= max
        ? undefined
        : { message: 'ra.validation.maxLength', args: { max } }
    }
  }

  const max = data.maxCharacters[key]
  if (max === undefined && hasShortKey(key)) {
    console.log(
      `%cError useMaxLengthValidator: index ${key} not found.`,
      'color: blue',
    )
    return false
  }
  return (value:undefined | string) => value && value.length <= max
    ? undefined
    : { message: 'ra.validation.maxLength', args: { max } }
}
