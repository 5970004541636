import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput
} from 'react-admin'
import { hasShortKey } from '../../utils'

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    filters={<Filters />}
    title={props.options.label}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      { hasShortKey(props.resource) && <TextField source="short" /> }
      <TextField source="name" />
    </Datagrid>
  </List>
)
