import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'

import { SelectorInput } from '../../inputs'

import { useUniqueValidator, useStringValidator } from '../../utils'

export default props => {
  const stringValidator = useStringValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'short')

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="short"
          validate={[required(), stringValidator, uniqueValidator]}
        />
        <TextInput source="name" validate={required()} />
        <SelectorInput
          source="brand"
          parse={string => string}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}
