import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput
} from 'react-admin'
import { AutocompleteCustom } from '../../inputs'
import { SelectorField } from '../../fields'

const Filters = props => {
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn />
      <AutocompleteCustom  source="brandId" reference="Brands" label="Brands" />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    filters={<Filters />}
    title={props.options.label}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField label="Id" source="short" />
      <TextField source="name" />
      <SelectorField source="brand" />
    </Datagrid>
  </List>
)
