import React, { FC } from 'react'

import { useGetOne } from 'react-admin'

import { useGetReferenceBySource } from '../utils'
import { IRecord } from '../types'

interface IFieldWithCurrency {
  value: string
  referenceId: string
  sourceReference: string
}

interface IField {
  source: string
  sourceReference: string
  record: IRecord
  addLabel: boolean
}

const FieldWithCurrency: FC<IFieldWithCurrency> = ({
  value,
  referenceId,
  sourceReference,
}) => {
  const reference = useGetReferenceBySource(sourceReference)
  const { data, loading } = useGetOne(reference, referenceId)
  if (loading === true) return null

  return <>{data && `${value}${data.symbol}`}</>
}

const Field: FC<IField> = ({ source, sourceReference, record }) => {
  const value = record[source]
  const valueCurrency = record[`${sourceReference}Id`]
  if (value === undefined) return <div>-</div>

  if (valueCurrency !== undefined) {
    return (
      <FieldWithCurrency
        value={value}
        referenceId={valueCurrency}
        sourceReference={sourceReference}
      />
    )
  }

  return <>{value}</>
}

Field.defaultProps = {
  source: 'amount',
  sourceReference: 'currency',
  addLabel: true,
}

export default Field
