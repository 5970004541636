import React, { useEffect, useState } from 'react'
import { Admin, Resource, Loading } from 'react-admin'
import {
  dataProvider,
  authProvider,
  hasPermission,
} from './providers/CrowdGraphQL'
import i18nProvider from './providers/i18nProvider'
import layout from './layout'
import loginPage from './pages/loginPage'

import * as Examples from './models/examples'
import * as Logs from './models/logs'
import * as Users from './models/users'
import * as Enums from './models/enums'
import * as Currencies from './models/currencies'
import * as Campaigns from './models/campaigns'
import * as Labels from './models/labels'
import * as Configs from './models/config'
import * as Products from './models/products'
import * as Events from './models/events'
import * as Brands from './models/brands'
import * as Destinations from './models/destinations'
// import * as BusinessAreas from './models/businessAreas'
import * as CampaignTypes from './models/campaignTypes'
import * as CampaignURLs from './models/campaignURLs'


import { useIsDev } from './utils'

const App = () => {
  const [provider, setProvider] = useState(null)
  const isDev = useIsDev()

  useEffect(() => {
    const callback = props => {
      setProvider(() => props)
    }
    dataProvider(callback)
  }, [])

  if (provider === null) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="please, wait for a while"
      />
    )
  }

  const appParameters = {
    dataProvider: provider,
    layout,
    i18nProvider,
    authProvider,
    loginPage,
  }

  return (
    <Admin {...appParameters}>
      {permissions => [
        <Resource
          name="Labels"
          {...Labels}
          create={hasPermission(permissions, Labels.create, {
            labelling: ['ROLE_ADMIN', 'ROLE_COSTS', 'ROLE_OWNER', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Events"
          {...Events}
          create={hasPermission(permissions, Events.create, {
            events: ['ROLE_ADMIN', 'ROLE_CREATOR', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="CampaignURLs"
          options={{ label: 'URLs' }}
          {...CampaignURLs}
          create={hasPermission(permissions, CampaignURLs.create, {
            labelling: ['ROLE_ADMIN', 'ROLE_COSTS', 'ROLE_OWNER', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="CampaignLevels"
          options={{ label: 'Campaign Levels' }}
          {...hasPermission(permissions, Enums, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Countries"
          options={{ label: 'Countries' }}
          {...hasPermission(permissions, Enums, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="ChannelSources"
          options={{ label: 'Channel Sources' }}
          {...hasPermission(permissions, Enums, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        // <Resource
        //   name="CampaignTypes"
        //   options={{ label: 'Campaign Types' }}
        //   {...hasPermission(permissions, Enums, {
        //     labelling: 'ROLE_ADMIN',
        //   })}
        // />,
        <Resource
          name="Agencies"
          options={{ label: 'Agencies' }}
          {...hasPermission(permissions, Enums, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Campaigns"
          options={{ label: 'Campaigns' }}
          {...hasPermission(permissions, Campaigns, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="CampaignTypes"
          options={{ label: 'Campaign Types' }}
          {...hasPermission(permissions, CampaignTypes, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Objectives"
          options={{ label: 'Objectives' }}
          {...hasPermission(permissions, Enums, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Brands"
          options={{ label: 'Brands' }}
          {...hasPermission(permissions, Brands, {
            labelling: 'ROLE_ADMIN',
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Destinations"
          options={{ label: 'Destinations' }}
          {...hasPermission(permissions, Destinations, {
            labelling: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Products"
          {...hasPermission(permissions, Products, {
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="TypeEvents"
          options={{ label: 'Types of Event' }}
          {...hasPermission(permissions, Enums, {
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="MediaTypes"
          options={{ label: 'Media Type' }}
          {...hasPermission(permissions, Enums, {
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Currencies"
          {...hasPermission(permissions, Currencies, {
            labelling: 'ROLE_ADMIN',
          })}
        />,
        <Resource
          name="CampaignURLsMediums"
          options={{ label: 'URLs Mediums' }}
          {...hasPermission(permissions, Enums, {
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="CampaignURLsSources"
          options={{ label: 'URLs Sources' }}
          {...hasPermission(permissions, Enums, {
            events: ['ROLE_ADMIN', 'ROLE_MANAGER'],
          })}
        />,
        <Resource
          name="Logs"
          {...hasPermission(permissions, Logs, {
            labelling: 'ROLE_ADMIN',
            events: 'ROLE_ADMIN',
          })}
        />,
        <Resource
          name="Users"
          {...hasPermission(permissions, Users, {
            labelling: 'ROLE_ADMIN',
            events: 'ROLE_ADMIN',
          })}
        />,
        <Resource
          name="Configs"
          {...hasPermission(permissions, Configs, {
            labelling: 'ROLE_ADMIN',
            events: 'ROLE_ADMIN',
          })}
        />,
        // <Resource
        //   name="BusinessAreas"
        //   {...hasPermission(permissions, BusinessAreas, {
        //     events: 'ROLE_ADMIN',
        //     labelling: 'ROLE_ADMIN',
        //   })}
        // />,
        isDev && <Resource name="Examples" {...Examples} />,
      ]}
    </Admin>
  )
}

export default App
