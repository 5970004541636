import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  NumberInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
  usePermissions,
  minValue,
  ReferenceManyField,
  Datagrid,
} from 'react-admin'

import {
  DateInput,
  SelectorInput,
} from '../../inputs'

import {
  CodeField,
  SelectorField,
  SelectorArrayField,
  DateField,
  UserField,
} from '../../fields'

import {
  useIntervalDateValidator,
} from '../../utils'

// import useBusinessAreaId from '../../utils/useBusinessAreaId'

const ModelToolbar = props => {
  const { permissions } = usePermissions()
  // const businessAreaId = useBusinessAreaId()

  return (
    <Toolbar {...props}>
      {/* {['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_COSTS'].includes(permissions?.labelling) && businessAreaId === props.record.businessAreaId?.toString() &&  (
        <SaveButton
          transform={(data) => {
              if (data.initialDate === '') data.initialDate = null;
              if (data.endDate === '') data.endDate = null;
              return data;
          }}
          submitOnEnter={false}
          redirect="edit"
        />
      )} */}
      {['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_COSTS'].includes(permissions?.labelling) && (
          <SaveButton
            transform={(data) => {
                if (data.initialDate === '') data.initialDate = null
                if (data.endDate === '') data.endDate = null
                return data
            }}
            submitOnEnter={false}
            redirect="edit"
          />
      )}
      <ListButton />
      {/* {['ROLE_ADMIN'].includes(permissions) && <RegenerateCodeButton />} */}
      {['ROLE_ADMIN' , 'ROLE_MANAGER',  'ROLE_COSTS'].includes(permissions?.labelling) && <DeleteButton />}
    </Toolbar>
  )
}

export default props => {
  const { permissions } = usePermissions()
  const intervalDateValidator = useIntervalDateValidator()

  const minValueValidator = [minValue(0)]

  return (
    <Edit {...props}>
      <TabbedForm redirect="edit" toolbar={<ModelToolbar />}>
        <FormTab label="Label parameters">
          <CodeField />
          <SelectorField source="campaignLevel" />
          <SelectorField source="country" />
          <SelectorField source="channelSource" />
          <SelectorField source="campaignType" />
          <SelectorField source="destination" />
          <SelectorField source="agency" />
          <SelectorField source="campaign" />
          <SelectorField source="objective" />
          <SelectorArrayField source="brands" />
          <TextField source="description" />
          {/* <SelectorField source="businessArea" sortable={false} /> */}
        </FormTab>
        {['ROLE_ADMIN', 'ROLE_COSTS'].includes(permissions?.labelling) && (
          <FormTab label="Cost allocation">
            <CodeField />
            <NumberInput source="amount" validate={minValueValidator} parse={v => v ? v.toString() : null} min={0}/>
            <SelectorInput source="currency" optional />
            <NumberInput source="impressions" validate={minValueValidator} step={1} parse={v => v ? v.toString() : null} min={0} />
            <DateInput source="initialDate" />
            <DateInput source="endDate" validate={intervalDateValidator} />
          </FormTab>
        )}
        <FormTab label="URLs">
          <ReferenceManyField perPage={999} label={null} reference="CampaignURLs" target="labelId" sort={{ field: 'createdAt', order: 'DESC' }} fullWidth>
            <Datagrid rowClick="edit">
              <SelectorField source="label" sortable={false} optionText="code" />
              <SelectorField source="source" sortable={false} />
              <SelectorField source="medium" sortable={false} />
              <TextField source="term"/>
              <TextField source="content"/>
              <DateField source="createdAt" />
              <UserField source="createdBy" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
