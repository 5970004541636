import React, { FC } from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceField,
  TextField,
  ReferenceFieldProps,
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

interface IReferenceFieldProps extends ReferenceFieldProps {
  optionText?: string
}

const Field: FC<IReferenceFieldProps> = ({
  source,
  optionText,
  ...props
}) => {
  const ref = useGetReferenceBySource(source)
  return (
    <ReferenceField source={`${source}Id`} {...props} reference={ref}>
      <TextField source={optionText} />
    </ReferenceField>
  )
}

Field.defaultProps = {
  optionText: 'name',
  addLabel: true,
  link: false,
}

Field.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  addLabel: PropTypes.bool,
  link: PropTypes.bool,
}

export default Field
