
import { useState, useEffect } from 'react'
import { useDataProvider } from 'react-admin'

export default (resource:string, field:string, id:string | null = null) => {
  const [actualVal, setActualVal] = useState()
  const dataProvider = useDataProvider()
  useEffect(() => {
    if (id)
      dataProvider.getOne(resource, { id })
        .then(response => {
          if (response?.data) setActualVal(response.data[field])
        })
  }, [resource, id, field, dataProvider])

  if (actualVal === undefined && id !== null) return undefined

  return async (value:string) => {
    if (value === actualVal) return undefined
    const { total } = await dataProvider.getList(
      resource,
      // @ts-ignore
      {
        filter: { [field]: value },
      },
    )
    return total === 0
      ? undefined
      : { message: 'ra.validation.unique', args: { value } }
  }
}
