import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

export default props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
    </SimpleForm>
  </Create>
)
