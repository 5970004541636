/* eslint-disable no-param-reassign */
import React, { FC } from 'react'
import { TextField, TextFieldProps } from 'react-admin'
import { IRecord } from '../types'

interface ITextFieldProps extends TextFieldProps{
  [key: string]: any;
  source: string;
  record: IRecord;
}

const Field:FC<ITextFieldProps> = ({ source, record, ...props }) => {
  const choise = record && source && record[source]
  if (choise === undefined || record === null) return null

  props.record = {
    [source]: choise,
  }
  props.source = source
  return <TextField {...props} />
}

Field.defaultProps = {
  source: 'model',
  addLabel: true,
}

export default Field
