import React, { FC } from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayFieldProps,
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

interface IReferenceArrayFieldProps extends ReferenceArrayFieldProps {
  source: string;
  optionText?: string;
  link?: boolean;
}

const Field:FC<IReferenceArrayFieldProps> =  ({ source, optionText, ...props }) => {
  const ref = useGetReferenceBySource(source)

  return (
    <ReferenceArrayField source={`${source}Ids`} {...props} reference={ref}>
      <SingleFieldList linkType={false}>
        <ChipField source={optionText} />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

Field.defaultProps = {
  optionText: 'name',
  addLabel: true,
  link: false,
}

Field.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  addLabel: PropTypes.bool,
  link: PropTypes.bool,
}

export default Field
