import React, { FC } from 'react'
import { SelectInput, SelectInputProps } from 'react-admin'

import { CHOICES } from '../fields/TypeField'

const Input:FC<SelectInputProps> = props => (
  <SelectInput
    {...props}
    choices={CHOICES}
  />
)

Input.defaultProps = {
  source: 'type',
}

export default Input
