import React from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'

const FilterSelectorUser = ({ ...props }) => {
  const optionRenderer = choice => `${choice.name} ${choice.surname}`
  return (
    <ReferenceInput {...props} sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText={optionRenderer} />
    </ReferenceInput>
  )
}

FilterSelectorUser.defaultProps = {
  addLabel: true,
  multiple: false,
  perPage: 999999,
}

export default FilterSelectorUser
