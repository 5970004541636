import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  ListButton,
  usePermissions,
  useShowController,
  BooleanField,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { Divider } from '@material-ui/core'
import { SelectorField, DateField, SelectorArrayField, DateTimeField, UserField, EventNameField } from '../../fields'
import useBusinessAreaId from '../../utils/useBusinessAreaId'

const Title = ({ record }) => record ? (
  <Typography variant="inherit">{record.eventName}</Typography>
) : null

const Actions = ({ basePath, data }) => {
  const { permissions } = usePermissions()
  const businessAreaId = useBusinessAreaId()
  return (
    <TopToolbar>
      {['ROLE_CREATOR', 'ROLE_MANAGER'].includes(permissions?.events) && businessAreaId === data?.businessAreaId?.toString() && <EditButton basePath={basePath} record={data} />}
      {['ROLE_ADMIN'].includes(permissions?.events) && <EditButton basePath={basePath} record={data} />}
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  )
}

export default props => {
  const { record } = useShowController(props)
  const has = camp => !!(record && record[camp])

  return (
    <Show title={<Title />} actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <DateField source="initialDate" />
        <DateField source="endDate" />
        <EventNameField />
        {has('eventDescription') && <TextField source="eventDescription" />}
        {has('eventObjective') && <TextField source="eventObjective" />}
        <SelectorArrayField source="countries" />
        <SelectorArrayField source="destinations" />
        <SelectorField source="typeEvent" />
        {has('mediaTypeId') && <SelectorField source="mediaType" />}
        {has('mediaSource') && <TextField source="mediaSource" />}
        {has('affiliateLink') && <BooleanField source="affiliateLink" />}
        {has('brandsIds') && <SelectorArrayField source="brands" />}
        {has('productsIds') && <SelectorArrayField source="products" />}
        <br />
        <Divider />
        <br />
        {has('isImport') && <TextField label="Import" source="isImport" />}
        <UserField source="createdBy" />
        {/* {has('businessAreaId') && <SelectorField source="businessArea" sortable={false} />} */}
        <DateTimeField source="createdAt" />
        <UserField source="updatedBy" />
        <DateTimeField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  )
}
