import React, { FC } from 'react'
import { DateInput, DateInputProps } from 'react-admin'
import PropTypes from 'prop-types'

const Input: FC<DateInputProps> = props => <DateInput {...props} />

Input.defaultProps = {
  locales: 'en-GB',
}

Input.propTypes = {
  locales: PropTypes.string,
  source: PropTypes.string.isRequired,
}

export default Input
