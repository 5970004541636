import decodeJwt from 'jwt-decode'

const tokenId = 'token'

export const getToken = () => localStorage.getItem(tokenId)
export const setToken = (newToken) => {
  localStorage.setItem(tokenId, newToken)
  const { id, permits, businessAreaId } = decodeJwt(newToken)
  localStorage.setItem('businessAreaId', businessAreaId)
  localStorage.setItem('permits', permits)
  localStorage.setItem('userId', id)
}
export const deleteToken = () => {
  localStorage.removeItem(tokenId)
  localStorage.removeItem('businessAreaId')
  localStorage.removeItem('permits')
  localStorage.removeItem('userId')
}

export default () => {
  return [getToken, deleteToken]
}
