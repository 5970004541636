import React,{useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin'
import {
    useDataProvider
  } from 'ra-core'

  import {
    TextField,
  } from '@material-ui/core'

import { useFormState } from 'react-final-form'
import { capitalize } from 'lodash'
import { useGetReferenceBySource } from '../utils'

const Input = ({source, from, singular=false, ...props}) => {
    const dataProvider = useDataProvider()
    const { values } = useFormState()
    const reference = useGetReferenceBySource(`${source}`)
    const [choices, setChoices] = useState([])

    useEffect(() => {
      if(values[`${from}Ids`]){
        getChoices(values[`${from}Ids`])
      }
    }, [values[`${from}Ids`]])// eslint-disable-line react-hooks/exhaustive-deps

    const getChoices = async (ids) => {
      let  filter = {}
      let res = await Promise.all(ids.map(async id => {
        singular ? filter[`${removeLastLetter(from)}Id`] = id : filter[`${from}Ids`] = id
        const response = await dataProvider
        .getList(capitalize(source), {
          pagination: { page: 1, perPage: 9999999 },
          filter: filter,
        })
        return response.data
      }))
      const choices = res.flat()
      setChoices(choices)
    }

    const removeLastLetter = (string) => {
        return string.slice(0, -1)
    }

    return (
      <>
        {choices.length===0 ? (
          <TextField label={capitalize(source)} variant="filled" margin="normal" InputProps={{ readOnly: true, defaultValue: `No choices found for selected ${from}` }} fullWidth />
        ): (
          <ReferenceArrayInput source={`${source}Ids`} reference={reference} label={capitalize(source)} {...props}>
            <SelectArrayInput choices={choices }/>
          </ReferenceArrayInput>
        )}  
      </>
    )
}

Input.defaultProps = {
    parse: array => array?.map(v => parseInt(v, 10)),
    format: array => array?.map(v => v ? String(v) : null),
    perPage: 200,
  }

  
  Input.propTypes = {
    source: PropTypes.string.isRequired,
    optionText: PropTypes.string,
    parse: PropTypes.func,
    format: PropTypes.func,
    perPage: PropTypes.number,
  }


export default Input

