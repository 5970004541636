import { useState, useEffect } from 'react'

const DEFAULT_BUSINESSAREAID = ''

export default () => {
  const [response, setResponse] = useState(DEFAULT_BUSINESSAREAID)
  useEffect(() =>{
    const getBusiness = async () => {
      const businessAreaId =  await localStorage.getItem('businessAreaId')
      setResponse(businessAreaId || DEFAULT_BUSINESSAREAID)
    }
    getBusiness()
  }, [])
  return response
}