import React from 'react'
import {
  Datagrid,
  List,
  Filter,
} from 'react-admin'

import {
  ModelField,
  TypeField,
  DateTimeField,
  UserField,
} from '../../fields'

import {
  ModelInput,
  TypeInput,
} from '../../inputs'

const Filters = props => {
  return (
    <Filter {...props}>
      <ModelInput />
      <TypeInput />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    filters={<Filters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="show">
      <DateTimeField source="createdAt" />
      <ModelField />
      <TypeField />
      <UserField source="createdBy" />
    </Datagrid>
  </List>
)
