export default (source: string) => {
  switch (source) {
    case 'campaignLevel':
      return 'CampaignLevels'
    case 'country':
    case 'countries':
      return 'Countries'
    case 'channelSource':
      return 'ChannelSources'
    case 'campaignType':
      return 'CampaignTypes'
    case 'destinations':
    case 'destination':
      return 'Destinations'
    case 'agency':
      return 'Agencies'
    case 'campaign':
      return 'Campaigns'
    case 'objective':
      return 'Objectives'
    case 'currency':
      return 'Currencies'
    case 'brands':
    case 'brand':
      return 'Brands'
    case 'typeEvent':
      return 'TypeEvents'
    case 'mediaType':
      return 'MediaTypes'
    case 'mediaSource':
      return 'MediaSources'
    case 'products':
    case 'product':
      return 'Products'
    case 'createdBy':
      return 'Users'
    case 'businessAreas':
    case 'businessArea':
      return 'BusinessAreas'
    case 'campaignURL':
      return 'CampaignURLs'
    case 'source':
      return 'CampaignURLsSources'
    case 'medium':
        return 'CampaignURLsMediums'
    case 'label':
        return 'Labels'
    default:
      console.log(
        `%cError useGetReferenceBySource: source ${source} not found`,
        'color: blue',
      )
      return 'undefined'
  }
}
