import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { SelectInput, TextInput, usePermissions } from 'react-admin'

import { CHOICES } from '../fields/RoleField'

import { IRecord } from '../types'

interface IInput {
  label: string;
  record: IRecord;
}

const Input:FC<IInput> = ({ label, ...props }) => {
  const { permissions } = usePermissions()
  const lowerLabel = label.toLowerCase()
  return ['ROLE_ADMIN'].includes(permissions ? permissions[lowerLabel] : '')
    ? <SelectInput choices={CHOICES.filter(({ platform }) => platform.includes(lowerLabel))} initialValue={props.record.permits[lowerLabel]} source={lowerLabel} {...props} />
    : <TextInput initialValue={props.record.permits[lowerLabel]} source={lowerLabel} {...props} disabled />
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
}

export default Input
